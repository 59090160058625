import React from 'react';

import {YStack} from '@medad-sep/core';

import {SideBarMenu} from './SideBarMenu';

export const MainMenu = () => {
  return (
    <YStack backgroundColor="$background" borderColor="transparent" paddingHorizontal="$6" paddingVertical="$12">
      <SideBarMenu highlightCurrentPage />
    </YStack>
  );
};
