import React, {useMemo} from 'react';

import {useTranslation} from 'react-i18next';

import {
  AppContentPage,
  AppPageType,
  AppServicePage,
  getLocalizedText,
  getServicePageDefaults,
  useAppletsList,
  useAppStructure,
  YStack,
} from '@medad-sep/core';

import {HighlightPagesCount} from '../../config/defaultLauncherPages';
import {LauncherPage} from '../../models/LauncherPage';

import {PageItem, PageItemProps} from './PageItem';
import {searchInString} from './utils';

export type PagesItems = Omit<LauncherPage, 'Component'>[];

interface Props {
  prePages?: PagesItems;
  searchValue?: string;
}

export const PagesList = ({searchValue, prePages = []}: Props) => {
  const appStructure = useAppStructure();
  const applets = useAppletsList();
  const {t, i18n} = useTranslation();

  const pageItem = (props: PageItemProps) => {
    return <PageItem {...props} key={props.index} />;
  };

  const appPages = useMemo(() => {
    const pages = [...prePages, ...appStructure?.pages?.slice(HighlightPagesCount)].reduce((accumulator, page, i) => {
      let icon, title, route, id;
      if ('type' in page) {
        if (page.type === AppPageType.CONTENT) {
          ({icon, title, id: route} = page as AppContentPage);
        } else if (page.type === AppPageType.SERVICE) {
          const service = getServicePageDefaults(page as AppServicePage, applets);
          ({Icon: icon, name: title, id: route} = service || {});
        }
      } else {
        ({icon, title, id: id} = page as LauncherPage);
      }

      const localizedTitle = getLocalizedText({value: title, t, i18n});

      if (!searchValue || searchInString(searchValue, localizedTitle)) {
        accumulator.push({id: route || id, icon, title, index: (prePages?.length || 0) + i});
      }

      return accumulator;
    }, [] as PageItemProps[]);

    if (searchValue) {
      pages.sort((page1, page2) => {
        const searchValueLower = searchValue.toLowerCase();
        const localizedTitle1 = getLocalizedText({value: page1.title, t, i18n});
        const localizedTitle2 = getLocalizedText({value: page2.title, t, i18n});

        const position1 = localizedTitle1.toLowerCase().indexOf(searchValueLower);
        const position2 = localizedTitle2.toLowerCase().indexOf(searchValueLower);

        return position1 - position2;
      });
    }

    return pages.map((page) => page && pageItem(page));
  }, [searchValue, prePages, appStructure?.pages]);

  return <YStack justifyContent="center">{appPages}</YStack>;
};
