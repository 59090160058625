import React from 'react';

import {AppContentPage} from '@medad-sep/core';
import {PagePreview} from '@medad-sep/core';

import {MainStackScreen} from '../MainStackNavigator';

export const getEditorPageView = (page: AppContentPage, Screen = MainStackScreen) => {
  const Component = () => <PagePreview key={page.id} page={page} />;

  return (
    <Screen
      key={page.id}
      name={page.id}
      initialParams={{title: page.title}}
      component={Component}
      options={{headerShown: false}}
    />
  );
};
