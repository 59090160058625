import React from 'react';

import {useNavigation} from '@react-navigation/native';

import {
  IconView,
  LocalizedText,
  LocalizedTextType,
  IconType,
  Typography,
  Pressable,
  XStack,
  Tooltip,
  View,
} from '@medad-sep/core';

interface Props {
  pageData: {id: string; title?: LocalizedTextType; icon: IconType};
  isExpanded: boolean;
  isActive?: boolean;
}

export const MenuItem = ({pageData, isExpanded, isActive}: Props) => {
  const navigation = useNavigation();

  return (
    <XStack width="100%" padding="$10">
      <Pressable flex={1} onPress={() => navigation.navigate(pageData.id as never)}>
        <XStack alignItems="center" justifyContent={isExpanded ? 'flex-start' : 'center'} flex={1} gap="$8">
          <Tooltip
            hide={isExpanded}
            allowFlip
            placement="left-start"
            content={
              <Typography color="$white">
                <LocalizedText value={pageData.title} />
              </Typography>
            }>
            <View
              key={`${isActive}`}
              animation="bouncy"
              enterStyle={isActive ? {scale: 0.8, y: 5} : null}
              scale={1}
              y={0}
              backgroundColor={isActive ? '$lightWhite' : '$transparent'}
              alignItems="center"
              width={32}
              height={32}
              justifyContent="center"
              borderRadius="$rounded-full">
              <IconView icon={pageData.icon} size={isActive ? 16 : 20} color="white" />
            </View>
          </Tooltip>
          {isExpanded && (
            <Typography key="text" color="white" fontWeight="600" marginTop={2}>
              <LocalizedText value={pageData.title} />
            </Typography>
          )}
        </XStack>
      </Pressable>
    </XStack>
  );
};
