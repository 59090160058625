import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {Platform} from 'react-native';
import RNRestart from 'react-native-restart';

import {
  shellScopedKey,
  useUserLangSelector,
  Select,
  Alert,
  coreScopedKey,
  getLocalizedText,
  IconView,
} from '@medad-sep/core';

import {SettingsItem} from '../models';
export const LanguageSelector = (): JSX.Element => {
  const {t, i18n} = useTranslation();
  const {languagesList, onLangChange} = useUserLangSelector();
  const [isRestartConfirmationVisable, setIsRestartConfirmationVisable] = useState(false);
  const onRestartLater = () => {
    setIsRestartConfirmationVisable(false);
    RNRestart.restart();
  };
  const onRestartConfirmation = () => {
    setIsRestartConfirmationVisable(false);
  };
  return (
    <>
      {Platform.OS !== 'web' && (
        <Alert
          title={getLocalizedText({value: shellScopedKey('settings.notice'), t, i18n})}
          show={isRestartConfirmationVisable}
          onAccept={onRestartLater}
          onCancel={onRestartConfirmation}
          content={getLocalizedText({value: coreScopedKey('languageChangeMsg'), t, i18n})}
          confirmText={getLocalizedText({value: shellScopedKey('settings.restartNow'), t, i18n})}
          cancelText={getLocalizedText({value: shellScopedKey('settings.later'), t, i18n})}
        />
      )}
      <Select
        inline
        value={i18n.language}
        onChange={async (value) => {
          await onLangChange(value);
          if (Platform.OS !== 'web') {
            setIsRestartConfirmationVisable(true);
          }
        }}
        trigger={<IconView icon="Globe" size={17} color="$neutral-600" />}
        options={languagesList?.map((lang) => ({value: lang, label: `language.${lang}`}))}
      />
    </>
  );
};
export const Language: SettingsItem = {
  id: 'language',
  name: shellScopedKey('settings.language'),
  Icon: 'Languages',
  Component: <LanguageSelector />,
};
