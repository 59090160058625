import React, {useMemo} from 'react';

import {
  AppPageType,
  AppContentPage,
  AppServicePage,
  getServicePageDefaults,
  AppStructure,
  Applet,
} from '@medad-sep/core';

import {MenuItem} from '../MenuItem';

interface MenuItemsParams {
  appStructure: AppStructure;
  applets: Applet[];
  currentPage?: string;
  isExpanded: boolean;
}

export const useMenuItems = ({appStructure, applets, currentPage, isExpanded}: MenuItemsParams) => {
  const createMenuItem = (pageData: any) => (
    <MenuItem key={pageData.id} isExpanded={isExpanded} pageData={pageData} isActive={currentPage === pageData.id} />
  );

  const getPageData = (page: any) => {
    if (page.type === AppPageType.CONTENT) {
      const {icon, title, id: route} = page as AppContentPage;
      return {icon, title, id: route};
    } else if (page.type === AppPageType.SERVICE) {
      const service = getServicePageDefaults(page as AppServicePage, applets);
      const {Icon: icon, name: title, id: route, hideNavigationHeader = false} = service || {};
      return {icon, title, id: route, hideNavigationHeader};
    }
  };

  return useMemo(() => {
    const pagesItems: React.JSX.Element[] = [];

    appStructure?.pages.forEach((page) => {
      const pageData = getPageData(page);
      if (pageData && !pageData.hideNavigationHeader) {
        const menuItem = createMenuItem(pageData);
        pagesItems.push(menuItem);
      }
    });

    return pagesItems;
  }, [appStructure, applets, currentPage, isExpanded]);
};
