import React from 'react';

import {Platform} from 'react-native';

import {XStack, YStack} from '@medad-sep/core';

import {MainHeader} from '../../MainHeader';
import {MainStackNavigator} from '../../MainStackNavigator';

import {MainMenu} from './components/MainMenu';

export const DesktopLauncher = ({children}: any) => {
  const height = Platform.select({web: '100vh', default: '100%'});
  return (
    <XStack height={height}>
      <MainMenu />

      <YStack flex={1}>
        <MainHeader />

        <YStack flexGrow={1} overflow="scroll">
          <MainStackNavigator>{children}</MainStackNavigator>
        </YStack>
      </YStack>
    </XStack>
  );
};
