export const ar = {
  home: 'الرئيسية',
  profiles: 'الحسابات',
  default: 'الأساسي',
  settings: {
    title: 'إعدادات',
    notice: 'تنويه',
    restartNow: 'إعادة التشغيل',
    switchNow: 'تغيير الان',
    setDefault: ' و ضع الأساسي',
    switchAccount: 'تغيير الحساب',
    logOut: 'تسجيل الخروج',
    later: 'لاحقاً',
    category: {
      app: 'إعدادات التطبيق',
      service: 'إعدادات الخدمات',
      help: 'مساعدة و دعم',
      privacy: 'الخصوصية',
    },
    privacyPolicy: 'سياسة الخصوصية',
    scheduleSetting: 'ضبط الجدول الزمني',
    termsAndConditions: 'الأحكام والشروط',
    connectWithUs: 'اتصل بنا',
    language: 'اللغة',
    darkMode: 'الوضع المظلم',
    userProfile: {
      title: 'حساب المستخدم',
      userName: 'اسم المستخدم',
      email: 'البريد الإلكتروني',
      firstName: 'الاسم الأول',
      lastName: 'اسم العائلة',
    },
  },
  timeLimit: {
    stayLoggedIn: 'البقاء مسجلا',
    logout: 'تسجيل الخروج',
    warningTitle: 'تحذير انتهاء مهلة الجلسة',
    warningMsg: 'ستنتهي جلستك قريبًا. هل ترغب في البقاء قيد تسجيل الدخول؟',
    timeRemaining: 'ثانية متبقية',
  },
  launcher: {
    sideBarTitle: 'الخدمات',
    notifications: {
      title: 'اشعارات',
    },
    allServices: {
      searchInput: 'ابحث عن خدمة ...',
      title: 'الخدمات',
      numOfServices: 'عدد الخدمات {{count}}',
    },
  },
  seeMore: 'شاهد المزيد',
  hi: 'مرحبا',
};
