import React, {useState, useMemo, useEffect} from 'react';

import {Trans} from 'react-i18next';

import {
  XStack,
  IconView,
  Button,
  Avatar,
  Text,
  YStack,
  ShellTrans,
  userInfoRegistry,
  RadioGroup,
  Radio,
  useUserSettings,
  appletScopedKey,
  Badge,
  Accordion,
  Typography,
  showToast,
  coreScopedKey,
} from '@medad-sep/core';

import {useSaveSelectedRoles} from '../api/useSaveSelectedRoles';

export const ProfileSelector = (): JSX.Element => {
  const [selectedRoles, setSelectedRoles] = useState<{[key: string]: string | null}>({});

  const onSelectRolesSuccess = () => {
    showToast({title: coreScopedKey('successMsg'), preset: 'done'});
  };
  const onSelectRolesError = () => {
    showToast({title: coreScopedKey('generalErrorMsg'), preset: 'done'});
  };

  const {mutateAsync: saveSelectedRoles} = useSaveSelectedRoles(onSelectRolesSuccess, onSelectRolesError);
  const {userSettings} = useUserSettings();
  const userInfo = userInfoRegistry.getUserInfo();

  const hasMultipleAccounts = useMemo(
    () => Object.keys(userInfo).some((key) => (userInfo[key]?.roles?.length || 0) > 1),
    [userInfo],
  );

  useEffect(() => {
    if (userSettings && !userSettings.selectedRoles) {
      const initialSelectedRoles = {};

      for (const key in userInfo) {
        const userRoles = userInfo[key]?.roles;
        const defaultRoleId = userInfo[key]?.defaultRoleId;
        const selectedRole = defaultRoleId ? userRoles?.find((role) => role.id === defaultRoleId) : userRoles?.[0]?.id;
        initialSelectedRoles[key] = selectedRole;
      }
      saveSelectedRoles(initialSelectedRoles);
      setSelectedRoles(initialSelectedRoles);
      return;
    }
    setSelectedRoles(userSettings?.selectedRoles || {});
  }, [userSettings]);

  const onSelectRole = (appletId: string, roleId: string) => {
    setSelectedRoles((prev) => ({...prev, [appletId]: roleId}));
  };

  const onSubmit = () => {
    saveSelectedRoles(selectedRoles);
  };

  return (
    <YStack justifyContent="space-between">
      {hasMultipleAccounts && (
        <YStack gap="$24">
          <XStack justifyContent="space-between" alignItems="center">
            <Text fontSize="$30" fontWeight="700">
              <ShellTrans i18nKey="profiles" />
            </Text>
            <Button prefix={<IconView icon="RefreshCw" />} onPress={() => onSubmit()}>
              <ShellTrans i18nKey="settings.switchNow" />
            </Button>
          </XStack>

          {Object.keys(userInfo).map((key) => {
            const roles = userInfo[key]?.roles;
            const rolesNumber = roles?.length || 0;
            if (rolesNumber > 1) {
              return (
                <Accordion
                  key={key}
                  headerBackgroundColor="$background"
                  sections={[
                    {
                      title: (
                        <Typography variant="h6" fontWeight="700">
                          <Trans i18nKey={appletScopedKey(key, '_name')} />
                        </Typography>
                      ),
                      content: (
                        <YStack>
                          {roles?.map((role) => {
                            return (
                              <XStack key={role.id}>
                                <RadioGroup
                                  flexDirection="row"
                                  width="100%"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  padding="$8"
                                  borderRadius="$6"
                                  value={selectedRoles[key] || ''}
                                  name={key}
                                  id={key}
                                  gap="$24"
                                  onValueChange={() => onSelectRole(key, role.id)}>
                                  <XStack flex={1} gap="$8" alignItems="center">
                                    <Avatar
                                      alignSelf="flex-start"
                                      margin="$1"
                                      borderColor="$highlightColor"
                                      backgroundColor="$accentLight"
                                      source={{uri: role.info?.imageUrl}}
                                      borderWidth={2}
                                      size="sm"
                                    />
                                    <Text fontWeight="600" fontSize="$14" minWidth="$8">
                                      {role.info?.username}
                                    </Text>

                                    <Badge
                                      alignSelf="center"
                                      label={
                                        <Text fontWeight="600" fontSize="$14" color="$neutral-600">
                                          {role.label}
                                        </Text>
                                      }
                                    />
                                  </XStack>
                                  <Radio size="large" value={role.id} id={key} />
                                </RadioGroup>
                              </XStack>
                            );
                          })}
                        </YStack>
                      ),
                    },
                  ]}
                />
              );
            }
          })}
        </YStack>
      )}
    </YStack>
  );
};
