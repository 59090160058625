import React from 'react';

import {useAppConfig, useAppImage, useApiConfig, Image, useAppTheme} from '@medad-sep/core';

export const Logo = () => {
  const appConfig = useAppConfig();
  const {environmentProfile} = useApiConfig();
  const {url: logoUrl} = useAppImage(appConfig.logo, environmentProfile);
  const isDarkMode = useAppTheme().theme === 'dark';

  if (!appConfig.logo) return null;

  return (
    <Image
      key={logoUrl}
      source={{uri: logoUrl}}
      resizeMode="contain"
      style={{
        height: 60,
        width: 180,
        maxWidth: '70%',
        filter: isDarkMode ? 'brightness(0) invert(1)' : 'unset',
      }}
      $gtSm={{height: 60}}
      $gtMd={{height: 50}}
    />
  );
};
