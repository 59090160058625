import {useMutation, useQueryClient} from 'react-query';

import {useAPI} from '@medad-sep/core';

type CB = () => void;

export function useSaveSelectedRoles(onSuccess: CB, onError: CB) {
  const API = useAPI();
  const queryClient = useQueryClient();

  return useMutation(
    (body: any) =>
      API.patch('user-settings/mySettings', {
        json: {
          settings: {selectedRoles: body},
        },
      }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('user-settings/mySettings');
        onSuccess();
      },
      onError,
    },
  );
}
