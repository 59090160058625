import {useMemo} from 'react';

import {BaseWidget, useAppletsList} from '@medad-sep/core';

import {SettingsItem} from '../models';
import {UserProfile} from '../sections/UserProfile';

export const useAppSettings = () => {
  const applets = useAppletsList();

  return useMemo(() => {
    const userConfigurations: BaseWidget[] = applets.reduce((accumulator, applet) => {
      if (applet.userConfigurations.length > 0) {
        return accumulator.concat(applet.userConfigurations);
      }
      return accumulator;
    }, [] as BaseWidget[]);

    const settingItems: Record<string, SettingsItem[] | BaseWidget[]> = {
      app: [UserProfile],
      service: userConfigurations,
    };

    return settingItems;
  }, [applets]);
};
