import React, {useEffect, useMemo, useState} from 'react';

import {StatusBar} from 'expo-status-bar';

import {
  AppContentPage,
  SafeAreaView,
  Theme,
  getServicePageDefaults,
  isAppContentPage,
  isAppServicePage,
  useAppStructure,
  useAppTheme,
  useAppletsList,
  useResponsiveValue,
} from '@medad-sep/core';

import {getEditorPageView} from './components/EditorPageView';
import {InactiveSession} from './components/InactiveSession';
import {DesktopLauncher} from './components/Launcher/DesktopLauncher';
import {MobileLauncher} from './components/Launcher/MobileLauncher';
import {MainStackScreen} from './components/MainStackNavigator';
import {getServicePage} from './components/ServicePage';
import {defaultLauncherPages} from './config/defaultLauncherPages';
import {loadShellLocalizations} from './localization';

export default function Shell() {
  const appStructure = useAppStructure();
  const applets = useAppletsList();
  const [isLoadedLocalizations, setIsLoadedLocalizations] = useState(false);
  const {theme} = useAppTheme();

  const Launcher = useResponsiveValue({$sm: MobileLauncher, $lg: DesktopLauncher});

  const appScreens = useMemo(() => {
    const screens: any = [];

    appStructure?.pages.forEach((page) => {
      if (isAppServicePage(page)) {
        const service = getServicePageDefaults(page, applets);
        service && screens.push(getServicePage(service));
      }

      if (isAppContentPage(page)) {
        screens.push(getEditorPageView(page as AppContentPage));
      }
    });

    defaultLauncherPages.forEach((page) =>
      screens.push(
        <MainStackScreen
          key={page.id}
          name={page.id}
          component={page.Component}
          initialParams={{title: page.title}}
          options={{headerShown: !page.hideNavigationHeader, navigationHeader: {title: page.title}}}
        />,
      ),
    );

    return screens;
  }, [applets, appStructure]);

  useEffect(() => {
    loadShellLocalizations();
    setIsLoadedLocalizations(true);
  }, []);

  if (!isLoadedLocalizations) return null;

  return (
    <SafeAreaView>
      <StatusBar style={theme === Theme.light ? 'dark' : 'light'} />
      <Launcher>{appScreens}</Launcher>
      <InactiveSession />
    </SafeAreaView>
  );
}
