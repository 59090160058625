export const en = {
  home: 'Home',
  profiles: 'Profiles',
  default: 'Default',
  settings: {
    title: 'Settings',
    notice: 'Notice',
    restartNow: 'Restart Now',
    switchNow: 'Switch Now',
    setDefault: 'Set Default',
    switchAccount: 'Switch Account',
    logOut: 'Log Out',
    later: 'Later',
    category: {
      app: 'App setting',
      service: 'Services Setting',
      help: 'Help & support',
      privacy: 'Privacy',
    },
    connectWithUs: 'Connect With Us',
    privacyPolicy: 'Privacy policy',
    scheduleSetting: 'My Schedule',
    termsAndConditions: 'Terms And Conditions',
    language: 'Language',
    darkMode: 'Dark Mode',
    userProfile: {
      title: 'User account',
      userName: 'User name',
      email: 'Email',
      firstName: 'First name',
      lastName: 'last name',
    },
  },
  timeLimit: {
    stayLoggedIn: 'Stay Logged in',
    logout: 'Log Out',
    warningTitle: 'Session Timeout Warning',
    warningMsg: 'Your session will expire soon. Would you like to stay logged in?',
    timeRemaining: 'Seconds Remaining',
  },
  launcher: {
    sideBarTitle: 'MY SERVICES',
    notifications: {
      title: 'Notifications',
    },
    allServices: {
      searchInput: 'Search for a service ...',
      title: 'All services',
      numOfServices: 'Number of services {{count}}',
    },
  },
  seeMore: 'See more',
  hi: 'Hi',
};
