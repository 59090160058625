import CORE_Applet from "@medad-sep/applet-core";
import MEDAD_CMP_Applet from "@medad-sep/applet-medad-cmp";
import MEDAD_ILS_Applet from "@medad-sep/applet-medad-opac";
import MEDAD_IEP_Applet from "@medad-sep/applet-medad-iep";
import BLACKBOARD_Applet from "@medad-sep/applet-blackboard";

export const appletsList = [
  CORE_Applet,
  MEDAD_CMP_Applet,
  MEDAD_ILS_Applet,
  MEDAD_IEP_Applet,
  BLACKBOARD_Applet
];
