import i18n from 'i18next';

import {shellScopedKey} from '@medad-sep/core';

import {ar} from './ar';
import {en} from './en';

const shellLocalization = {ar, en};

export function loadShellLocalizations() {
  Object.keys(shellLocalization).forEach((langKey) => {
    const lang = (shellLocalization as any)[langKey];
    i18n.addResourceBundle(langKey, 'translation', {[shellScopedKey()]: lang}, true, true);
  });
}
